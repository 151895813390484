import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persianFa from 'react-date-object/locales/persian_fa';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFDatePicker({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, isDirty, error },
        formState: { errors },
      }) => (
        <div style={{ marginTop: 8, width: '100%' }}>
          <span style={{ color: 'gray' }}>{label}</span>
          <DatePicker
            calendarPosition={`top-start`}
            containerStyle={{
              width: '100%',
            }}
            style={{
              borderColor: errors[name] ? 'red' : 'gray',
              borderRadius: 8,
              fontSize: '1rem',
              width: '100%',
              paddingTop: 25,
              paddingBottom: 25,
              paddingRight: 14,
              paddingLeft: 14,
              fontFamily: 'Vazir',
            }}
            name={name}
            calendar={persian}
            locale={persianFa}
            value={new Date(parseInt(value, 10)) || ''}
            onChange={(date) => {
              onChange({ target: { name, value: date?.toDate?.()?.getTime() } });
            }}
            format={'YYYY/MM/DD'}
          />
          {errors && errors[name] && errors[name].type === 'required' && (
            <span style={{ marginRight: 16, fontSize: 12, color: 'red' }}>{error?.message}</span>
          )}
        </div>
      )}
    />
  );
}
