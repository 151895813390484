import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'app', element: <GeneralApp /> },
        // { path: 'ecommerce', element: <GeneralEcommerce /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ],
        },
        {
          path: 'organization',
          children: [
            { element: <Navigate to="/dashboard/organization/list" replace />, index: true },
            { path: 'list', element: <OrganizationList /> },
            { path: 'new', element: <OrganizationCreate /> },
            { path: ':id/edit', element: <OrganizationCreate /> },
          ],
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company/list" replace />, index: true },
            { path: 'list', element: <CompanyList /> },
            { path: 'new', element: <CompanyCreate /> },
            { path: ':id/edit', element: <CompanyCreate /> },
          ],
        },
        {
          path: 'department',
          children: [
            { element: <Navigate to="/dashboard/department/list" replace />, index: true },
            { path: 'list', element: <DepartmentList /> },
            { path: 'new', element: <DepartmentCreate /> },
            { path: ':id/edit', element: <DepartmentCreate /> },
          ],
        },
        {
          path: 'subject',
          children: [
            { element: <Navigate to="/dashboard/subject/list" replace />, index: true },
            { path: 'list', element: <SubjectList /> },
            { path: 'new', element: <SubjectCreate /> },
            { path: ':id/edit', element: <SubjectCreate /> },
          ],
        },
        {
          path: 'topic',
          children: [
            { element: <Navigate to="/dashboard/topic/list" replace />, index: true },
            { path: 'list', element: <TopicList /> },
            { path: 'new', element: <TopicCreate /> },
            { path: ':id/edit', element: <TopicCreate /> },
          ],
        },
        {
          path: 'violation',
          children: [
            { element: <Navigate to="/dashboard/violation/list" replace />, index: true },
            { path: 'list', element: <ViolationList /> },
            { path: 'new', element: <ViolationCreate /> },
            { path: ':id/edit', element: <ViolationCreate /> },
          ],
        },
        {
          path: 'offender',
          children: [
            { element: <Navigate to="/dashboard/offender/list" replace />, index: true },
            { path: 'list', element: <OffenderList /> },
            { path: 'new', element: <OffenderCreate /> },
            { path: ':id/edit', element: <OffenderCreate /> },
          ],
        },
        {
          path: 'action',
          children: [
            { element: <Navigate to="/dashboard/action/list" replace />, index: true },
            { path: 'list', element: <ActionList /> },
            { path: 'new', element: <ActionCreate /> },
            { path: ':id/edit', element: <ActionCreate /> },
          ],
        },
        {
          path: 'dispart',
          children: [
            { element: <Navigate to="/dashboard/dispart/list" replace />, index: true },
            { path: 'list', element: <DispartList /> },
            { path: 'new', element: <DispartCreate /> },
            { path: ':id/edit', element: <DispartCreate /> },
          ],
        },
        {
          path: 'pursuit',
          children: [
            { element: <Navigate to="/dashboard/pursuit/list" replace />, index: true },
            { path: 'list', element: <PursuitList /> },
            { path: 'new', element: <PursuitCreate /> },
            { path: ':id/edit', element: <PursuitCreate /> },
          ],
        },
        {
          path: 'actionType',
          children: [
            { element: <Navigate to="/dashboard/actionType/list" replace />, index: true },
            { path: 'list', element: <ActionTypeList /> },
            { path: 'new', element: <ActionTypeCreate /> },
            { path: ':id/edit', element: <ActionTypeCreate /> },
          ],
        },
        {
          path: 'membership',
          children: [
            { element: <Navigate to="/dashboard/membership/list" replace />, index: true },
            { path: 'list', element: <MembershipList /> },
            { path: 'new', element: <MembershipCreate /> },
            { path: ':id/edit', element: <MembershipCreate /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user/UserCreate')));
const OrganizationList = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationList')));
const OrganizationCreate = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationCreate')));
const CompanyList = Loadable(lazy(() => import('../pages/dashboard/company/CompanyList')));
const CompanyCreate = Loadable(lazy(() => import('../pages/dashboard/company/CompanyCreate')));
const DepartmentList = Loadable(lazy(() => import('../pages/dashboard/department/DepartmentList')));
const DepartmentCreate = Loadable(lazy(() => import('../pages/dashboard/department/DepartmentCreate')));
const SubjectList = Loadable(lazy(() => import('../pages/dashboard/subject/SubjectList')));
const SubjectCreate = Loadable(lazy(() => import('../pages/dashboard/subject/SubjectCreate')));
const TopicList = Loadable(lazy(() => import('../pages/dashboard/topic/TopicList')));
const TopicCreate = Loadable(lazy(() => import('../pages/dashboard/topic/TopicCreate')));
const ViolationList = Loadable(lazy(() => import('../pages/dashboard/violation/ViolationList')));
const ViolationCreate = Loadable(lazy(() => import('../pages/dashboard/violation/ViolationCreate')));
const OffenderList = Loadable(lazy(() => import('../pages/dashboard/offender/OffenderList')));
const OffenderCreate = Loadable(lazy(() => import('../pages/dashboard/offender/OffenderCreate')));
const ActionList = Loadable(lazy(() => import('../pages/dashboard/action/ActionList')));
const ActionCreate = Loadable(lazy(() => import('../pages/dashboard/action/ActionCreate')));
const DispartList = Loadable(lazy(() => import('../pages/dashboard/dispart/DispartList')));
const DispartCreate = Loadable(lazy(() => import('../pages/dashboard/dispart/DispartCreate')));
const PursuitList = Loadable(lazy(() => import('../pages/dashboard/pursuit/PursuitList')));
const PursuitCreate = Loadable(lazy(() => import('../pages/dashboard/pursuit/PursuitCreate')));
const ActionTypeList = Loadable(lazy(() => import('../pages/dashboard/actionType/ActionTypeList')));
const ActionTypeCreate = Loadable(lazy(() => import('../pages/dashboard/actionType/ActionTypeCreate')));
const MembershipList = Loadable(lazy(() => import('../pages/dashboard/membership/MembershipList')));
const MembershipCreate = Loadable(lazy(() => import('../pages/dashboard/membership/MembershipCreate')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
