import axios from 'axios';
// config
import { HOST_API } from '../config';
// const HOST_API = 'http://192.168.100.51:8001'
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
