// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'مدیریت',
    items: [
      // MANAGEMENT : USER
      {
        title: 'کاربر',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'ویرایش', path: PATH_DASHBOARD.user.create },
          // { title: 'اضافه کردن', path: PATH_DASHBOARD.user.create },
        ],
      },
      {
        title: 'افراد بیمار',
      path: PATH_DASHBOARD.organization.root,
        icon: ICONS.user,
        children: [
          { title: 'لیست', path: PATH_DASHBOARD.organization.list },
          { title: 'اضافه کردن', path: PATH_DASHBOARD.organization.create },
        ],
      },
      {
        title: 'سابقه بیماری‌ها',
        path: PATH_DASHBOARD.company.root,
        icon: ICONS.user,
        children: [
          { title: 'لیست', path: PATH_DASHBOARD.company.list },
          // { title: 'اضافه کردن', path: PATH_DASHBOARD.company.create },
        ],
      },
      // {
      //   title: 'گروه ها',
      //   path: PATH_DASHBOARD.department.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.department.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.department.create },
      //   ],
      // },
      // {
      //   title: 'دسته بندی ها',
      //   path: PATH_DASHBOARD.subject.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.subject.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.subject.create },
      //   ],
      // },
      // {
      //   title: 'عنوان ها',
      //   path: PATH_DASHBOARD.topic.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.topic.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.topic.create },
      //   ],
      // },
      // {
      //   title: 'تخلف ها',
      //   path: PATH_DASHBOARD.violation.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.violation.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.violation.create },
      //   ],
      // },
      // {
      //   title: 'متخلف ها',
      //   path: PATH_DASHBOARD.offender.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.offender.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.offender.create },
      //   ],
      // },
      // {
      //   title: 'اقدام ها',
      //   path: PATH_DASHBOARD.action.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.action.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.action.create },
      //   ],
      // },
      // {
      //   title: 'علت قطع همکاری ها',
      //   path: PATH_DASHBOARD.dispart.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.dispart.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.dispart.create },
      //   ],
      // },
      // {
      //   title: 'پیگیری ها',
      //   path: PATH_DASHBOARD.pursuit.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.pursuit.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.pursuit.create },
      //   ],
      // },
      // {
      //   title: 'نوع اقدام ها',
      //   path: PATH_DASHBOARD.actionType.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.actionType.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.actionType.create },
      //   ],
      // },
      // {
      //   title: 'نوع عضویت ها',
      //   path: PATH_DASHBOARD.membership.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.membership.list },
      //     { title: 'اضافه کردن', path: PATH_DASHBOARD.membership.create },
      //   ],
      // },
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },

      // // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
